import React from 'react';

export function Avatar (){
    return(
        <div className="md-2 my-2 justify-content-between text-center heading">
            <img src='images/avatar.png' alt = "icon" className="avatar"/>
            <h3 className='mt-2 mb-0'><span>Hi there 👋,</span> I'm</h3>
            <h2 className='mb-2'>Pinakin Patel<span className="dot"></span></h2>
            <p>I specialize in building complex web applications, leading front-end teams, digital product design and developing visual design systems. I enjoy creating effortless user experience and designing delightful digital products. The entire process of going from a concept to release and gathering user’s feedback on either client’s or my own products is what makes me wake up everyday!
I worked with numerous clients from all around the world from early startups to well-established companies. I always seek new opportunities for cooperation on projects. Let’s create something awesome together!
</p>
        </div>
    )
}