import React from 'react';

export function Skill() {
    return (
        <div className="md-2 my-5 justify-content-between text-center heading">
            <h3 className="font-weight-bold"><span className="dot"></span>someSkills()</h3>
        </div>
    );
}

export function Portfolio() {
    return (
        <div className="md-2 my-5 justify-content-between text-center heading">
            <h3 className="font-weight-bold"><span className="dot"></span>latestWorks()</h3>
        </div>

    );
}

export function Experiments() {
    return (
        <div className="md-2 my-5 justify-content-between text-center heading">
            <h3 className="font-weight-bold"><span className="dot"></span>someExperiments()</h3>
        </div>

    );
}