import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import SmoothScroll from "./components/SmoothScroll/SmoothScroll";
import { Particles } from 'react-particles-js';
import particlesConfig from './config/configParticles';

import { Container, Row, Col } from 'react-bootstrap';
import { PortfolioCard } from './components/PortfolioCard';
import { Confirmation } from './components/Confirmation';
import { Skills } from './components/Skills';
import { Avatar } from './components/Avatar';
import { Skill, Portfolio, Experiments } from './components/Heading';
import { Social } from './components/Footer';

import project from './port';
import experiment from './exp';
import skill from './skill';

function App() {
  return (
    <div className="App" style={{ position: 'relative', overflow: "hidden" }}>
      <div class="cursor"></div>
      <div style={{ position: 'absolute' }}>
        <Particles height="100vh" width="100vw" params={particlesConfig} />
      </div>
      {/*<SmoothScroll>*/}
        <Container>
          <div className="info">
            <Row>
              <Avatar />
            </Row>
          </div>
          <div className="skill">
          <Row>
            <Skill /> {/*Skills heading*/}
          </Row>
          <div className="skills">
            <Row className="justify-content-md-center h-100">
              {skill.map(data => (
                <Col xs={12} sm={12} md={3} lg={3} className='mb-5' key={`${data.id}`}>
                  <Skills data={data} />
                </Col>
              ))
              }
            </Row>
          </div>
          </div>
          <div className="portfolio">          
          <Row>
            <Portfolio /> {/*Portfolio heading*/}
            {project.map(data => (
              <Col xs={12} sm={12} md={4} className='mb-5' key={`${data.id}`}>
                <PortfolioCard data={data} />
              </Col>
            ))
            }
          </Row>
          </div>
          <div className="experiment"> 
          <Row>
            <Experiments /> {/*Experiments heading*/}
          </Row>
          <Row>
            {experiment.map(data => (
              <Col xs={12} sm={12} md={4} className='mb-5' key={`${data.id}`}>
                <Confirmation data={data} />
              </Col>
            ))
            }
          </Row>
          </div>
          <div className="footer">
            <Row>
              <Social/>
            </Row>

          </div>
        </Container>
      {/*</SmoothScroll>*/}
    </div>
  );
}

export default App;
