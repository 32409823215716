import React from 'react';
import { Card } from 'react-bootstrap';

export function Skills ({ data }){
    return(
        <Card className="rounded skillcard m-auto">
            <Card.Body className="flex-column">
                <div className="md-2 justify-content-between">
                    <div className="icon mb-4"><Card.Img variant="top" src={data.image} /></div>
                    <Card.Title className="mb-5 font-weight-bold">{data.skill}</Card.Title>
                    <Card.Text className="mb-0 font-weight-bold">{data.desc}</Card.Text>
                </div>
            </Card.Body>
        </Card>
    )
}