import React from 'react';
import { Card } from 'react-bootstrap';

export function Confirmation ({ data }){
    return(
        <a href={data.url} className="text-decoration-none text-dark" target="_blank">
        <Card className="h-100 bg-white rounded expcard">
            <Card.Body className="flex-column">
                <div className="md-2 justify-content-between text-center">
                    <Card.Title className="mb-0 font-weight-bold">{data.name}</Card.Title>
                    <Card.Text className="mb-0 font-weight-bold">{data.desc}</Card.Text>
                </div>
            </Card.Body>
        </Card>
        </a>
    )
}