import React from 'react';
import { Card } from 'react-bootstrap';

export function PortfolioCard ({ data }){
    return(
        <Card className="h-100 bg-white rounded portfoliocard">
            <Card.Img variant="top" src={data.image}/>
            <Card.Body className="flex-column">
                <div className="md-2 justify-content-between text-center">
                    <Card.Title className="mb-0 font-weight-bold">{data.name}</Card.Title>
                </div>
                <Card.Text className="text-secondery text-center">{data.desc}</Card.Text>
                <Card.Text className="text-secondery text-center"><span className="tag-label">{data.tag1}</span><span className="tag-label">{data.tag2}</span><span className="tag-label">{data.tag3}</span></Card.Text>
            </Card.Body>
        </Card>
    )
}